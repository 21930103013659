import React, { Suspense, useEffect, useMemo, useRef } from 'react';

import { BrowserRouter, Route, Switch, useLocation } from 'react-router-dom';

import { StoreProvider, useStoreContext } from './context/store';
import { LanguageProvider } from './context/LanguageContext';

import './styles/shards.css'
import './styles/antd-mobile.css';
import './styles/antd.css';
import './assets/styles/styles.scss';
import './assets/styles/styles-web.scss';
import './assets/styles/styles-iframe.scss';
import './assets/styles/styles-tablet.scss';
import './assets/styles/styles-playground.scss';
import './assets/styles/app-themes.scss';

// Auth & Generals
import routes from './routes';

import { parse } from 'query-string';
import { bridge } from './utils/bridge';
import classNames from 'classnames';

import get from 'lodash/get';
import ReactResizeDetector from 'react-resize-detector';
import { loadI18n } from './utils/i18n';

import i18n from './i18n';

import env from './env';
import { usePalette, useTheme } from './hooks/sdk';

loadI18n(i18n);

// const getGAevents = (label) => {
//   return {
//     Load: {
//       category: 'Feedback',
//       action: 'Load Smile Form',
//       label: label,
//     },
//   };
// };

const NoMatch = () => <div>Página no encontrado o no tiene permisos</div>;

// const LoadingScreen = () => (
//   <div
//     style={{
//       justifyContent: 'center',
//       alignItems: 'center',
//       display: 'flex',
//       position: 'fixed',
//       top: '0',
//       left: '0',
//       width: '100vw',
//       height: '100vh',
//     }}
//   >
//     <img style={{ height: '75px' }} data-testid="loading" src={Gif} alt="Loading..." />
//   </div>
// );

export const App = ({ children }) => {
  const containerRef = useRef();
  const { context, dispatch, instance } = useStoreContext();

  const location = useLocation();

  const theme = useTheme();
  const palette = usePalette();

  const appMode = get(context, 'state.appMode', 'iframe');
  const { ui } = context;

  useEffect(() => {
    const query = parse(location.search);
    if (get(query, 'v')) delete query.v;

    instance.sdk.appendUserContext({
      query,
    });

  }, [instance, dispatch, location.search]);

  useEffect(() => {
    window.$context = context;
    const callback = () => console.log(instance.sdk.userContext);
    window.addEventListener('smile/user-context/log', callback);
    return () => {
      window.removeEventListener('smile/user-context/log', callback);
    };
  }, [instance, context]);

  const CSSPalette = useMemo(() => {
    return {
      ...instance.sdk.getDefaultPalette(),
      ...palette,
    };
  }, [instance, palette]);

  bridge.on('theme', (theme) => {
    window.localStorage.setItem('mt-smile', theme)
  });

  return (
    <div className="scroll-container" style={get(ui, 'scrollContainerStyle')}>
      <ReactResizeDetector
        handleHeight
        onResize={(width, height) => {
          bridge.emit('size', {
            height,
            width,
          });
        }}
      >
        <div
          ref={containerRef}
          className={classNames('app-container', localStorage.getItem('mt-smile') === 'dark' && 'dark-theme', [
            appMode,
            ...theme.templates,
          ])}
          style={CSSPalette}
        >
          {children ? children : (
            <Suspense
              fallback={null}>
              <Switch>
                {routes.map((route, index) => (
                  <PrivateRoute
                    key={route.path}
                    path={route.path}
                    route={route}
                    exact={route.exact}
                  />
                ))}
                <Route component={() => <NoMatch />} />
              </Switch>
            </Suspense>
          )}
        </div>
      </ReactResizeDetector>
    </div>
  );
};

const AppWrapper = ({ storeRef }) => {
  const Router = BrowserRouter;
  return (
    <LanguageProvider>
      <StoreProvider storeRef={storeRef}>
        <Router
          initialEntries={['/']}
          initialIndex={1}
          basename={env.REACT_APP_BASENAME || ''}
        >
          <App />
        </Router>
      </StoreProvider>
    </LanguageProvider>
  );
};

const PrivateRoute = ({ route, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <route.layout>
        <route.component {...props} />
      </route.layout>
    )}
  />
);

export default AppWrapper;
