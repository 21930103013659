import React, { useContext, useEffect } from 'react'
import { MenuItem, Select, FormControl } from '@material-ui/core';
import { LanguageContext } from '../context/LanguageContext';
import { useStoreContext } from '../context/store';
import { useTranslation } from 'react-i18next';
import { AiOutlineTranslation } from 'react-icons/ai';
import { InputAdornment } from '@material-ui/core';

const LanguageSelector = () => {
  const { language, setLanguage, supportedLanguages } = useContext(LanguageContext);
  const { instance } = useStoreContext();
  const { i18n } = useTranslation();

  const changeLanguage = (event) => {
    setLanguage(event.target.value.toLowerCase());
  };

  useEffect(() => {
    if (!language) return null;
    instance.changeLanguage(language);
    i18n.changeLanguage(language);
  }, [language, i18n]);

  return (
    <FormControl variant="outlined" style={{
      position: 'fixed',
      top: 10,
      right: 30,
      zIndex: 1000,
      minWidth: '100px',
      background:'#fff',
      borderRadius:'9px'
    }}>
      <Select
        startAdornment={
          <InputAdornment position="start">
            <AiOutlineTranslation size={18} />
          </InputAdornment>
        }
        labelId="language-select-label"
        value={language ? language : 'es'}
        onChange={changeLanguage}
      >
      {supportedLanguages && supportedLanguages.map((lang) => (
        <MenuItem key={lang} value={lang}>
          {lang.toUpperCase()}
        </MenuItem>
      ))}
      </Select>
    </FormControl>
  )
}

export default LanguageSelector;