import get from 'lodash/get';
import React, { useCallback, useEffect, useState } from 'react';
import { useStoreContext } from '../context/store';
import SmileSelect from './SmileSelect';
import { useI18n } from '../utils/i18n';
import { set } from 'lodash';

function setDefaultStores(stores = [], setGroups, groups, withGroup = true) {
  if (!withGroup) return [];
  const newGroups = [];
  const finalStores = stores.map((store) => {
    const storeGroup = store?.group;
    if (storeGroup && !newGroups.includes(storeGroup))
      newGroups.push(storeGroup);
    return {
      value: store.key,
      caption: store.nombre_sucursal,
      key: store.key,
      metadata: store.metadata,
      group: storeGroup,
    };
  });
  if (groups?.length > 0) return [...finalStores];
  setGroups([
    ...newGroups.sort(function (a, b) {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    }),
  ]);
  return [...finalStores];
}

export const StoreSelector = ({ question, value, onChange }) => {
  const withGroup = get(question, 'metadata.settings.group', false);
  const { context, dispatch, instance } = useStoreContext();
  const { data } = context;
  const campaign = data.campaign;
  const [filters, setFilters] = useState(undefined);
  const [groups, setGroups] = useState(undefined);
  const [stores, setStores] = useState(undefined);
  const [storesFiltered, setStoresFiltered] = useState(undefined);
  const [groupSelected, setGroupSelected] = useState(data?.store?.group);
  const isRequired = get(question, 'metadata.settings.required', true);
  const groupSelectorName = get(question, 'metadata.settings.group.name', '');
  const t = useI18n();

  useEffect(() => {
    if (stores?.length > 0) return;
    if (!data?.stores) return;
    setStores(setDefaultStores(data?.stores, setGroups, groups, withGroup));
  }, [data?.stores]);

  useEffect(() => {
    const stores_filters = get(campaign, 'metadata.stores_filters', []);
    if (stores_filters.length === 0) return;
    setFilters([...stores_filters]);
  }, [campaign]);

  useEffect(() => {
    if (stores?.length > 0) return;
    if (!get(data, 'hash') || get(data, 'hash', '') === 'playground') return;
    (async () => {
      const storesResponse = await instance.sdk.getStores();
      setStores(setDefaultStores(storesResponse, setGroups));
    })();
  }, [instance, data]);

  function setStore(key) {
    const currentStore = stores.find((store) => store.key === key);
    onChange({ ...value, store_key: key });
    dispatch({
      type: 'data.update',
      payload: {
        store: currentStore ? { ...currentStore } : key,
      },
    });
  }

  useEffect(() => {
    setStoresFiltered(undefined);
    if (!groupSelected && !filters) return;
    const result = [];
    if (!stores) return;

    stores.forEach((store) => {
      if(!filters && groupSelected && store.group === groupSelected) result.push(store);
      if(filters && filters.every(filter=> get(store,`metadata.${filter.mapping}`) === filter.value)) result.push(store);
    });

    setStoresFiltered([...result]);
  }, [stores,groupSelected, filters]);

  function modifyFilters(index,value){
    setStore(undefined);
    const newFilters = [...filters];
    set(newFilters[index],'value',value);
    setFilters([...newFilters]);
  }

  const getValueFilters = useCallback((filter,index)=>{
    const result = [];
    stores.forEach((store)=>{
      const value = get(store,`metadata.${filter.mapping}`);
      if(!value) return;
      let filterValueSelected = [];
      if(index>0) filterValueSelected = filters.slice(0,index);
      const condition = filterValueSelected.every((filterValue)=>{
        return get(store,`metadata.${filterValue.mapping}`) === filterValue.value
      });
      if(condition && !result.find((valueResult)=> valueResult.value === value)) result.push({value: value,caption: value,key: value}); 
    });
    
    return [...result].sort(function (a, b) {
      if (a.value < b.value) return -1;
      if (a.value > b.value) return 1;
      return 0;
    });
  },[filters]);

  const hasGroup = groups && withGroup && groups?.length > 1;

  return (
    <>
      {filters
        ? filters.map((filter, index) => (
            <SmileSelect
              autocomplete={true}
              style={{ margin: '10px' }}
              dataTestId={`filterstores${filter.name}`}
              value={filter.value}
              label={filter.name}
              disabled={index>0 && !filters.slice(0,index).every(filter=> filter?.value)}
              onChange={(ev) => modifyFilters(index,ev.target.value)}
              options={getValueFilters(filter,index)}
            />
          ))
        : hasGroup && (
            <SmileSelect
              autocomplete={true}
              style={{ margin: '10px' }}
              dataTestId={`formgroupstores`}
              value={groupSelected}
              label={groupSelectorName}
              onChange={(ev) => setGroupSelected(ev.target.value)}
              options={groups.map((group) => {
                return {
                  value: group,
                  caption: group,
                  key: group,
                };
              })}
            />
          )}
      <SmileSelect
        autocomplete={true}
        style={{ margin: '10px' }}
        dataTestId={`formstores`}
        value={get(data, 'store.key')}
        label={t('stores')}
        required={isRequired}
        disabled={filters ? !filters.every(filter => filter.value) : hasGroup && !groupSelected}
        onChange={(ev) => setStore(ev.target.value)}
        options={storesFiltered ? storesFiltered : stores || []}
        // showError={get(value,'errors',[]).length>0}
      />
    </>
  );
};
