import get from 'lodash/get';

export const validateRequiredFields = (context, enabledModules) => {
  const { userData, userContactData, answers } = context;

  const { poll, campaign } = context.data

  // VALIDATE POLL QUESTIONS
  let errorsInForm = undefined;
  const modules = get(poll, 'modules', []);

  if (modules.length) {
    errorsInForm = enabledModules.reduce((reduced, item) => {
      return [...reduced, ...item.getErrors({answers})];
    }, []);
  }


  // Validate User Data

  const formModule = modules.find((module) => module.type === 'form');
  let formWasVisited = undefined;

  if(formModule) {
    let module = answers.find(answer => answer.module.type === 'form');
    formWasVisited = module?.visited === 'returned' || !module?.visited ? false : true;
  }

  const requiredContactData = get(campaign, 'metadata.form.enabled', true);

  let invalidUserDataResponse = false;

  userContactData.forEach((field) => {
    if(get(field,'required', true) && !get(field,'response')) invalidUserDataResponse = true;
  });

  if(get(userData, 'emailValidation', true) === false || get(userData, 'birthdayValidation') === false) {
    invalidUserDataResponse = true;
  }
  if (
    (errorsInForm && errorsInForm.length) ||
    (invalidUserDataResponse && (formWasVisited || requiredContactData))
  ) {
    return false;
  }

  return true;
};