import React, { createContext, useState } from 'react';

const LanguageContext = createContext();
const supportedLanguages = [
  'es',
  'en',
];

const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(null);

  return (
    <LanguageContext.Provider value={{ language, setLanguage, supportedLanguages }}>
      {children}
    </LanguageContext.Provider>
  );
};

export { LanguageContext, LanguageProvider, supportedLanguages };